import tw, { css } from 'twin.macro';
import { BREAKPOINTS } from '../../constants';

export const LogoWrapper = css`
z-index: 25;
width: 196px;
height: 58px;
${tw`inline-block mx-auto relative`};
@media screen and (max-width: ${BREAKPOINTS.xs}px){
        width: auto;
}
`;
